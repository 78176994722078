import http from './http-common';
class WordsListService {
    getWordsLists() {
        return http.get('/words-lists');
    }

    getAWordsList(id) {
        return http.get(`/words-lists/${id}`);
    }

    createWordsLists(data) {
        return http.post('/words-lists', data);
    }

    updateWordsList(id, data) {
        return http.put(`/words-lists/${id}`, data);
    }

    removeWordsList(id) {
        return http.delete(`/words-lists/${id}`);
    }
}

export default new WordsListService();