import React, { useEffect, useRef, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Field, Form } from "react-final-form";
import { Button, TextField } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { default as MuiLink } from "@material-ui/core/Link/Link";
import { Link } from "react-router-dom";
import BackdropLoader from "../../components/BackdropLoader/Backdrop";
import Alert from "../../components/Alert/Alert";
import UserService from "../../http-services/user.service";
import { i18n } from "../../utils/i18n";
import { Trans } from "@lingui/macro";
import UpdateProfileForm from "../../components/Forms/Profile/UpdateProfileForm";
import ChangePasswordForm from "../../components/Forms/Profile/ChangePasswordForm";
import wordsListService from '../../http-services/words-list.service';
import CreateWordsListForm from '../../components/Forms/WordsLists/CreateWordsListForm';
import EditWordsListsForm from '../../components/Forms/WordsLists/EditWordsListsForm';

const WordsLists = (props) => {
    const [loading, setLoading] = useState(true);
    const [wordsLists, setWordsLists] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const alertRef = useRef();

    useEffect(() => {
        wordsListService.getWordsLists()
            .then(response => {
                setWordsLists(response.data);
                setLoading(false);
            })
            .catch(error => {
                if (error.response) {
                    alertRef.current.toggle('error', error.response.data);
                } else {
                    alertRef.current.toggle('error', error);
                }
                setLoading(false);
            })
    }, []);

    const getItemByName = (name) => {
        for (const item of wordsLists)
            if (item.name == name) return item;
        return null;
    };

    const getSelectedItem = () => {
        return selectedItem ? getItemByName(selectedItem) : null;
    };

    const deleteWordsList = (item) => {
        const wordsList = getItemByName(item);
        if (!wordsList) return;

        setLoading(true);
        if (item == selectedItem) setSelectedItem(null);

        wordsListService.removeWordsList(wordsList._id)
            .then(() => {
                setWordsLists(state => [...(state.filter(i => i.name != item))]);
            })
            .catch(error => {
                alertRef.current.toggle('error', error.response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const updateWordsList = (values) => {
        setSelectedItem(null);
        return new Promise(async resolve => {
            if (!values.name)
                return resolve(false);

            const wordsList = getItemByName(values.name);
            if (!wordsList) return resolve(false);

            wordsListService.updateWordsList(wordsList._id, {
                words: values.words.join(';')
            })
                .then(response => {
                    setWordsLists(state => [...(state.filter(i => i.name != values.name)), response.data]);
                    resolve(true);
                })
                .catch(error => {
                    alertRef.current.toggle('error', error.response.data);
                    resolve(false);
                });
        });
    };

    const createWordsList = (values) => {
        return new Promise(async resolve => {
            wordsListService.createWordsLists({
                name: values.name,
                words: values.words.join(';')
            })
                .then(response => {
                    setWordsLists(state => [...state, response.data]);
                    resolve(true);
                })
                .catch(error => {
                    alertRef.current.toggle('error', error.response.data);
                    resolve(false);
                });
        });
    };

    return (
        <>
            <BackdropLoader open={loading} />
            <Alert ref={alertRef} />
            <EditWordsListsForm items={wordsLists} onDelete={deleteWordsList} onClick={item => setSelectedItem(item)} selectedItem={selectedItem} />
            <hr style={{ margin: '20px 0' }} />
            <CreateWordsListForm onSubmit={selectedItem ? updateWordsList : createWordsList} onCancel={() => setSelectedItem(null)} updateItem={getSelectedItem()} />
        </>
    );
}

export default WordsLists;